import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import magicLink from 'utils/magicLink'
import Preload from 'components/Preload'

const Logout = () => {
  const history = useHistory()

  useEffect(() => {
    magicLink.user.logout().then(() => {
      window.location.href = `${window.location.origin}/login`
    })
  }, [history])

  return <Preload />
}

export default Logout
