import { GET_PROFILE } from 'redux/actionTypes'
import { getAPI } from 'utils/api'

export const getProfile = () => (dispatch) => {
  dispatch({ type: GET_PROFILE.REQUEST })
  return getAPI({
    url: '/profile',
  })
    .then((data) => {
      dispatch({
        type: GET_PROFILE.SUCCESS,
        payload: data,
      })
    })
    .catch((error) => Promise.reject(dispatch({ type: GET_PROFILE.FAILURE, error })))
}
