import React, { useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { TextField, Button } from '@material-ui/core'

import magicLink from 'utils/magicLink'
import { validateEmail } from 'utils/validation'
import imageLogo from '../assets/images/logo-color.svg'

const Div = styled.div`
  padding-top: 24vh;

  .logo {
    display: block;
    width: 90px;
    height: 42px;
    margin: 0 auto;
  }

  .title {
    margin: 35px 0;
    text-align: center;
    color: #afb7c0;
    font-size: 14px;
  }

  .login-wrapper {
    margin: 0 auto;
    padding-top: 40px;
    padding: 30px;
    width: 330px;
    background: #fff;
    border-radius: 8px;

    .MuiButton-root {
      margin-top: 35px;
      padding-top: 10px;
      padding-bottom: 10px;
      text-transform: none;
    }
  }
`

const Login = () => {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [isLoggingIn, setIsLoggingIn] = useState(false)

  const handleLogin = useCallback(
    async (e) => {
      e.preventDefault()
      setIsLoggingIn(true)

      try {
        await magicLink.auth.loginWithMagicLink({ email })
        history.push('/')
      } catch {
        setIsLoggingIn(false)
      }
    },
    [email, history]
  )

  return (
    <Div>
      <img className="logo" src={imageLogo} />
      <div className="title">Web Portal</div>
      <div className="login-wrapper">
        <form onSubmit={handleLogin}>
          <TextField
            label="Email"
            fullWidth
            value={email}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoggingIn || !validateEmail(email)}
            fullWidth
          >
            Sign in
          </Button>
        </form>
      </div>
    </Div>
  )
}

export default Login
