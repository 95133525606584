import { GET_PRODUCT_LIST } from 'redux/actionTypes'
import { getAPI } from 'utils/api'
import { SKYBLUE_API_URL } from 'configs/app.config'

export const getProductList = () => (dispatch) => {
  dispatch({ type: GET_PRODUCT_LIST.REQUEST })
  return getAPI({
    baseURL: SKYBLUE_API_URL,
    url: '/v1/products',
  })
    .then((data) => {
      dispatch({
        type: GET_PRODUCT_LIST.SUCCESS,
        payload: data,
      })
    })
    .catch((error) => Promise.reject(dispatch({ type: GET_PRODUCT_LIST.FAILURE, error })))
}
