import { SET_AUTH, CLEAR_AUTH } from 'redux/actionTypes'

const initialState = {}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_AUTH:
      return action.payload
    case CLEAR_AUTH:
      return initialState
    default:
      return state
  }
}
