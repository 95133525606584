import { GET_PROFILE } from 'redux/actionTypes'

const initialState = {
  id: '',
  email: '',
  mobilePhoneNumber: '',
  name: '',
  companyId: '',
  companyName: '',
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_PROFILE.SUCCESS:
      return action.payload
    default:
      return state
  }
}
