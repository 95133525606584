import { GET_BRANCH, SWITCH_BRANCH } from 'redux/actionTypes'
import { getAPI } from 'utils/api'

export const getBranch = () => (dispatch) => {
  dispatch({ type: GET_BRANCH.REQUEST })
  return getAPI({
    url: '/branches',
  })
    .then((data) => {
      dispatch({
        type: GET_BRANCH.SUCCESS,
        payload: data,
      })
    })
    .catch((error) => Promise.reject(dispatch({ type: GET_BRANCH.FAILURE, error })))
}

export const switchBranch = (branchId) => ({
  type: SWITCH_BRANCH,
  branchId,
})
