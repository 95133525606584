import React from 'react'
import styled from 'styled-components'

import imagePreload from 'assets/images/preload.gif'

const Div = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  img {
    width: 104px;
  }
`

const Preload = () => (
  <Div>
    <img src={imagePreload} />
  </Div>
)

export default Preload
