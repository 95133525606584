import { GET_BRANCH, SWITCH_BRANCH } from 'redux/actionTypes'

const mapBranch = ({ id, name, companyName }) => ({
  id,
  name,
  companyName,
})

const initialState = {
  selectedId: null,
  list: [],
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_BRANCH.SUCCESS: {
      const { payload = [] } = action
      const branchList = payload.map(mapBranch)
      return {
        ...state,
        selectedId: branchList[0]?.id || null,
        list: branchList,
      }
    }
    case SWITCH_BRANCH: {
      return {
        ...state,
        selectedId: action.branchId,
      }
    }
    default:
      return state
  }
}
