import { GET_QRCODE } from 'redux/actionTypes'

const mapQrcode = ({ id, branchName, organizationName, content, assets }) => ({
  id,
  branchName,
  organizationName,
  content,
  assets,
})

const initialState = {
  fetching: false,
  data: {},
  error: null,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_QRCODE.REQUEST: {
      return {
        ...state,
        fetching: true,
      }
    }
    case GET_QRCODE.SUCCESS: {
      const {
        payload: { data = [], branchId },
      } = action
      const qrcodeList = data.map(mapQrcode)
      return {
        ...state,
        fetching: false,
        data: {
          ...state.data,
          [branchId]: qrcodeList,
        },
        error: null,
      }
    }
    case GET_QRCODE.FAILURE: {
      return {
        ...state,
        fetching: false,
        error: action.error,
      }
    }
    default:
      return state
  }
}
