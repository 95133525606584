import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import SidebarLayout from 'components/layout/SidebarLayout'
import SlideAlert from 'containers/SlideAlert'
import Login from 'pages/Login'
import Logout from 'pages/Logout'
import routes from 'routes/routes'
import SecureRoute from 'routes/SecureRoute'

export default () => {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} exact />
        <Route path="/logout" component={Logout} exact />
        <SecureRoute>
          <SidebarLayout>
            <Switch>
              {routes.map(({ path, component }) => (
                <Route key={path} path={path} component={component} exact />
              ))}
            </Switch>
          </SidebarLayout>
        </SecureRoute>
      </Switch>
      <SlideAlert />
    </Router>
  )
}
