import React from 'react'
import styled from 'styled-components'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { TextField, InputAdornment } from '@material-ui/core'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'

const StyledDate = styled(DatePicker)`
  .MuiInputBase-root {
    width: 150px;
    border: 1px solid #e1e4ea;
    background: #fff;
    border-radius: 8px;
    font-size: 14px;
    color: #2c2c2c;
    padding: 0px 3px 0px 13px;
    height: 33px;

    .MuiInputAdornment-positionStart .MuiSvgIcon-root {
      width: 14px;
    }

    .MuiInputBase-input {
      padding: 7px 0 7px;
    }

    &:before,
    &:after {
      visibility: hidden;
    }
  }
`

const CustomTextField = (props) => {
  return (
    <TextField
      {...props}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CalendarTodayIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <KeyboardArrowDownIcon />
          </InputAdornment>
        ),
      }}
    />
  )
}

const Date = (props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <StyledDate
        variant="inline"
        format="dd-MM-yyyy"
        disableToolbar
        autoOk
        TextFieldComponent={CustomTextField}
        {...props}
      />
    </MuiPickersUtilsProvider>
  )
}

export default Date
