import React from 'react'
import styled from 'styled-components'
import { withTheme, hexToRgb } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import { NavLink } from 'react-router-dom'

import routes from 'routes/routes'
import imgLogo from 'assets/images/logo.svg'
import iconClose from 'assets/images/icon-close.svg'

const colorAlpha = '10'

const Div = withTheme(styled.div`
  height: 100%;
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};

  .sideber-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 20px 14px;
    background: #3886eb;

    .logo {
      img {
        display: block;
        width: 36px;
      }
    }

    .close-btn {
      img {
        display: none;
        width: 14px;
        cursor: pointer;
      }
    }
  }

  ul {
    padding: 17px 10px;

    li {
      list-style: none;
      margin-bottom: 25px;
      text-align: center;
    }

    .MuiButtonBase-root {
      width: 42px;
      color: ${({ theme }) => theme.palette.primary.contrastText};
      border-radius: 8px;
      margin: 0 auto;

      &.active {
        background-color: #3886eb !important;
      }

      &:hover {
        background: ${({ theme }) =>
          hexToRgb(`${theme.palette.primary.contrastText}${colorAlpha}`)};
      }
    }

    .menu-label {
      display: none;
      font-size: 14px;
      margin-top: 16px;
    }
  }

  @media (max-width: 850px) {
    .sideber-header {
      .close-btn {
        img {
          display: block;
        }
      }
    }

    ul {
      .menu-label {
        display: block;
      }
    }
  }
`)

export default ({ onSidebarClose }) => (
  <Div>
    <div className="sideber-header">
      <div className="logo">
        <img src={imgLogo} />
      </div>
      <div className="close-btn" onClick={onSidebarClose}>
        <img src={iconClose} />
      </div>
    </div>
    <ul>
      {routes
        .filter(({ menu }) => menu)
        .map(({ path, icon, label }) => (
          <li key={path}>
            <IconButton component={NavLink} to={path} color="secondary" exact>
              <img src={icon} />
            </IconButton>
            <div className="menu-label">{label}</div>
          </li>
        ))}
    </ul>
  </Div>
)
