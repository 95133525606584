import {
  GET_NOTIFICATION_LIST,
  UPDATE_NOTIFICATION_LIST,
  READ_NOTIFICATION,
} from 'redux/actionTypes'
import { getAPI } from 'utils/api'
import { updateTransactionList } from 'redux/actions/transaction.action'
import { selectNotifaication, selectTransaction } from 'redux/selectors'

export const getNotification =
  (branchId, afterId, replace = true) =>
  (dispatch) => {
    dispatch({ type: GET_NOTIFICATION_LIST.REQUEST })
    return getAPI({
      url: `/branches/${branchId}/notifications`,
      params: {
        after: afterId,
      },
    })
      .then(({ data = [] }) => {
        const lastRead = localStorage.getItem(`lastRead${branchId}`)
        const hasNewCount = Boolean(replace && data[0] && data[0].id !== lastRead)

        return dispatch({
          type: GET_NOTIFICATION_LIST.SUCCESS,
          payload: {
            list: data,
            newCount: hasNewCount ? 1 : 0,
            replace,
          },
        })
      })
      .catch((error) => Promise.reject(dispatch({ type: GET_NOTIFICATION_LIST.FAILURE, error })))
  }

export const updateNotification = (branchId) => (dispatch, getState) => {
  const { filter } = selectTransaction(getState())
  const { list } = selectNotifaication(getState())
  const firstId = list[0]?.id
  const { page } = filter
  return getAPI({
    url: `/branches/${branchId}/notifications`,
    params: {
      before: firstId,
    },
  })
    .then(({ data = [] }) => {
      if (data.length && page === 1) {
        // Update transaction list when get new transaction
        dispatch(updateTransactionList())
      }
      dispatch({ type: UPDATE_NOTIFICATION_LIST, payload: { list: data, newCount: data.length } })
    })
    .catch(() => {})
}

export const readNotification = (branchId) => (dispatch, getState) => {
  const { list } = selectNotifaication(getState())
  const firstId = list[0]?.id
  if (firstId) {
    localStorage.setItem(`lastRead${branchId}`, firstId)
  }
  return dispatch({ type: READ_NOTIFICATION })
}
