export const selectLoading = (state) => state.loading

export const selectAlert = (state) => state.alert

export const selectAccessToken = (state) => state.auth.token

export const selectNotifaication = (state) => state.notification

export const selectProfile = (state) => state.profile

export const selectBranch = (state) => state.branch
export const selectCurrentBranchId = (state) => state.branch.selectedId
export const selectCurrentBranch = (state) => {
  const { selectedId, list } = state.branch
  return list.find((v) => v.id === selectedId)
}

export const selectProduct = (state) => state.product
export const selectProductById = (id) => (state) => state.product[id]

export const selectQrcode = (state) => state.qrcode
export const selectQrcodeByBranchId = (id) => (state) => state.qrcode.data[id]

export const selectTransaction = (state) => state.transaction
