import React from 'react'
import ReactDOM from 'react-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import store from 'redux/store'
import App from 'App'

import 'assets/css/global.css'
import '@progress/kendo-theme-material/dist/all.css'
import { Provider } from 'react-redux'

library.add(fas)
library.add(far)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
