import QRCode from 'pages/QRCode'
import Transactions from 'pages/Transactions'
import PageNotFound from 'pages/PageNotFound'

import IconTransaction from 'assets/images/icon-transaction.svg'
import IconQrcode from 'assets/images/icon-qrcode.svg'

export default [
  {
    path: '/',
    icon: IconTransaction,
    component: Transactions,
    label: 'Transactions',
    menu: true,
  },
  {
    path: '/qrcode',
    icon: IconQrcode,
    component: QRCode,
    label: 'QR Code',
    menu: true,
  },
  {
    path: '*',
    component: PageNotFound,
  },
]
