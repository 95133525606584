import { SET_AUTH, CLEAR_AUTH } from 'redux/actionTypes'

export const setAuth = (data) => ({
  type: SET_AUTH,
  payload: data,
})

export const logout = () => ({
  type: CLEAR_AUTH,
})
