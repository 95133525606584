import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import loading from 'redux/reducers/loading.reducer'
import alert from 'redux/reducers/alert.reducer'
import auth from 'redux/reducers/auth.reducer'
import notification from 'redux/reducers/notification.reducer'
import profile from 'redux/reducers/profile.reducer'
import branch from 'redux/reducers/branch.reducer'
import product from 'redux/reducers/product.reducer'
import qrcode from 'redux/reducers/qrcode.reducer'
import transaction from 'redux/reducers/transaction.reducer'

const composeEnhancers =
  (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

const enhancer = composeEnhancers(applyMiddleware(thunk))

export default createStore(
  combineReducers({
    loading,
    alert,
    auth,
    notification,
    profile,
    branch,
    product,
    qrcode,
    transaction,
  }),
  enhancer
)
