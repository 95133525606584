import React from 'react'
import { Provider } from 'react-redux'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'

import store from 'redux/store'
import theme from 'assets/themes/main.theme'
import Router from 'routes/Router'
import MainLoading from 'containers/MainLoading'

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router />
        <MainLoading />
      </ThemeProvider>
    </Provider>
  )
}

export default App
