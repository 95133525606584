import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import QRCodeGen from 'react-qr-code'
import { Button, CircularProgress } from '@material-ui/core'
import html2canvas from 'html2canvas'
import { saveAs } from 'file-saver'

import { getQrcode } from 'redux/actions/qrcode.action'
import {
  selectQrcode,
  selectQrcodeByBranchId,
  selectCurrentBranchId,
  selectProduct,
} from 'redux/selectors'

import iconDownload from 'assets/images/icon-download.svg'
import iconPrint from 'assets/images/icon-print.svg'

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .qrcode-wrapper {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
  }

  .qrcode-title {
    background: #2060a9;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    padding: 14px 24px;
  }

  .qrcode-content {
    padding: 20px 24px;
    background: #fff;
    text-align: center;

    .asset {
      margin-bottom: 10px;

      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin: 0 10px;
      }
    }

    .company {
      margin-top: 15px;
      font-size: 16px;
      font-weight: bold;
    }

    .branch {
      font-size: 12px;
      color: #5f666f;
    }
  }

  .qrcode-footer {
    display: flex;
    margin: 15px -6px 0;

    .MuiButton-root {
      flex: 1;
      margin: 0 6px;
      justify-content: flex-start;
      text-transform: none;
      font-weight: normal;
      padding: 2px 15px;

      img {
        margin-right: 10px;
      }
    }
  }

  .qrcode-download {
    position: absolute;
    left: -1000px;
    border-radius: 8px;
    overflow: hidden;

    .qrcode-content {
      border: 1px solid #c2c2c2;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  @media print {
    .qrcode-footer {
      display: none;
    }
  }
`

const QRCode = () => {
  const currentBranchId = useSelector(selectCurrentBranchId)
  const { fetching } = useSelector(selectQrcode)
  const qrcodeList = useSelector(selectQrcodeByBranchId(currentBranchId))
  const productList = useSelector(selectProduct)
  const [qrcodeDetail] = qrcodeList || []
  const dispatch = useDispatch()
  const imgRef = useRef()

  const handleSaveImage = () => {
    html2canvas(imgRef.current, { useCORS: true }).then((canvas) => {
      saveAs(canvas.toDataURL('image/jpeg', 1))
    })
  }

  useEffect(() => {
    dispatch(getQrcode(currentBranchId))
  }, [currentBranchId, dispatch])

  const renderQRcode = (hideButton) => {
    return (
      <>
        <div className="qrcode-title">QR Code</div>
        <div className="qrcode-content">
          <div className="asset">
            {qrcodeDetail.assets.map(({ id }) => {
              const { imageUri } = productList[id] || {}
              return imageUri ? <img key={id} src={imageUri} /> : null
            })}
          </div>
          <QRCodeGen value={qrcodeDetail.content} level="H" />
          <div className="company">{qrcodeDetail.organizationName}</div>
          <div className="branch">{qrcodeDetail.branchName}</div>
          {!hideButton && (
            <div className="qrcode-footer">
              <Button
                className="button-print"
                variant="outlined"
                color="primary"
                onClick={window.print}
              >
                <img src={iconPrint} />
                Print
              </Button>
              <Button
                className="button-download"
                variant="outlined"
                color="primary"
                onClick={handleSaveImage}
              >
                <img src={iconDownload} />
                Download
              </Button>
            </div>
          )}
        </div>
      </>
    )
  }

  if (fetching && !qrcodeDetail) {
    return (
      <Div>
        <CircularProgress />
      </Div>
    )
  }

  if (!qrcodeDetail) {
    return <Div>No QR Code</Div>
  }

  return (
    <Div>
      <div className="qrcode-download" ref={imgRef}>
        {renderQRcode(true)}
      </div>
      <div className="qrcode-wrapper">{renderQRcode()}</div>
    </Div>
  )
}

export default QRCode
