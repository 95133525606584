import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { IconButton, Popover } from '@material-ui/core'
import moment from 'moment'

import IconNoti from 'assets/images/icon-noti.svg'
import {
  getNotification,
  updateNotification,
  readNotification,
} from 'redux/actions/notification.action'
import { selectCurrentBranchId, selectNotifaication } from 'redux/selectors'

const Div = styled.div`
  display: none;

  > .MuiIconButton-root {
    padding: 5px;

    img {
      width: 19px;
    }

    .noti-number {
      position: absolute;
      top: 3px;
      right: 1px;
      width: 13px;
      height: 13px;
      background: #db5656;
      color: #fff;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }
  }
`

const PopoverWrapper = styled.div`
  width: 300px;
  max-height: 400px;
  overflow-y: auto;

  .noti-header {
    position: sticky;
    top: 0;
    width: 100%;
    padding: 15px;
    font-family: 'Inter';
    font-size: 12px;
    border-bottom: 1px solid #f4f6f7;
    background: #fff;
    color: #173254;
  }

  .noti-item {
    padding: 5px 15px;
    font-size: 14px;
    border-top: 1px solid #f4f6f7;

    .title {
      font-size: 12px;
      font-weight: bold;
      color: #3886eb;
    }

    .detail {
      font-family: 'Inter';
      font-size: 12px;
      color: #2c2c2c;
    }

    .time {
      margin-top: 5px;
      color: #5f666f;
      font-family: 'Inter';
      font-size: 10px;
    }

    &:first-child {
      border: none;
    }
  }

  .noti-loading {
    padding-bottom: 5px;
    text-align: center;
    font-size: 12px;
    color: #5f666f;
  }
`

const NotificationContent = ({ items, onLoadMore, loading }) => {
  const notiWrapper = useRef(null)
  const notiContent = useRef(null)

  useEffect(() => {
    const { current: elem } = notiContent || {}
    const options = {
      root: notiWrapper.current,
      rootMargin: '0px',
      threshold: 0.1,
    }

    const cb = (entries) => {
      const [entry] = entries
      if (entry.isIntersecting) {
        onLoadMore()
      }
    }

    const observer = new IntersectionObserver(cb, options)
    if (elem) {
      observer.observe(elem)
    }

    return () => {
      if (elem) {
        observer.unobserve(elem)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  const convertTime = (timestamp) => {
    if (moment().diff(moment(timestamp), 'days') < 1) {
      return moment(timestamp).fromNow()
    }
    return moment(timestamp).format('DD MMM YYYY HH:mm')
  }

  return (
    <PopoverWrapper ref={notiWrapper}>
      {items.length > 0 ? (
        <>
          <div className="noti-header">Lastest Notifications</div>
          <div className="noti-content">
            {items.map((v, i) => (
              <>
                <div key={v.id} className="noti-item">
                  <div className="title">{v.title}</div>
                  <div className="detail">{v.message}</div>
                  <div className="time">{convertTime(v.timestamp)}</div>
                </div>
                {i === items.length - 5 && <div ref={notiContent} />}
              </>
            ))}
          </div>
        </>
      ) : (
        <div className="noti-header">No notification</div>
      )}
      {loading && <div className="noti-loading">Loading...</div>}
    </PopoverWrapper>
  )
}

const Notification = () => {
  const dispatch = useDispatch()
  const { list, unread, isLoading, isEnding } = useSelector(selectNotifaication)
  const currentBranchId = useSelector(selectCurrentBranchId)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    dispatch(readNotification(currentBranchId))
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLoadMore = () => {
    if (!isEnding) {
      const [lastItem] = list.slice(-1)
      dispatch(getNotification(currentBranchId, lastItem?.id, false))
    }
  }

  useEffect(() => {
    let timer = null
    const pullingNotification = () => {
      timer = setTimeout(() => {
        dispatch(updateNotification(currentBranchId)).finally(pullingNotification)
      }, 3000)
    }

    dispatch(getNotification(currentBranchId)).then(() => pullingNotification())
    return () => clearTimeout(timer)
  }, [currentBranchId, dispatch])

  return (
    <Div>
      <IconButton onClick={handleClick}>
        <img src={IconNoti} />
        {/* {unread > 0 && <div className="noti-number">{unread > 9 ? '9+' : unread}</div>} */}
        {unread > 0 && <div className="noti-number" />}
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <NotificationContent items={list} onLoadMore={handleLoadMore} loading={isLoading} />
      </Popover>
    </Div>
  )
}

export default Notification
