import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { GridColumn as Column } from '@progress/kendo-react-grid'
import { Button, CircularProgress } from '@material-ui/core'

import PageTitle from 'components/PageTitle'
import CustomTable from 'components/table/CustomTable'
import CellDateTime from 'components/table/CellDateTime'
import CellToken from 'components/table/CellToken'
import Select from 'components/form/Select'
import Date from 'components/form/Date'
import {
  getTransactionList,
  updateTransactionFilter,
  downloadTransactionList,
} from 'redux/actions/transaction.action'
import { getQrcode } from 'redux/actions/qrcode.action'
import {
  selectCurrentBranchId,
  selectProduct,
  selectTransaction,
  selectQrcodeByBranchId,
  selectProductById,
} from 'redux/selectors'
import { TIME_PERIOD } from 'constants/transaction.constant'

import iconDownload from 'assets/images/icon-download.svg'
import iconSuccess from 'assets/images/icon-success.svg'
import iconFail from 'assets/images/icon-fail.svg'

const Td = styled.td`
  .text {
    display: inline;
    padding: 4px 8px;
    background: #ecfdf2;
    border-radius: 4px;
    color: #1e9045;
  }

  .symbol {
    display: none;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;

      img {
        display: block;
        width: 14px;
      }

      &.status-success {
        background: #ecfdf2;
      }

      &.status-fail {
        background: #fff1f2;
      }
    }
  }

  .amount {
    color: #1e9045;
  }

  @media (max-width: 1100px) {
    .text {
      display: none;
    }

    .symbol {
      display: block;
    }
  }
`

const CellStatus = ({ dataItem, field }) => {
  const value = dataItem[field]
  return (
    <Td>
      <div className="text">{value}</div>
      <div className="symbol">
        {value === 'Success' ? (
          <div className="status-success">
            <img src={iconSuccess} />
          </div>
        ) : (
          <div className="status-fail">
            <img src={iconFail} />
          </div>
        )}
      </div>
    </Td>
  )
}

const CellAmount = ({ dataItem, field }) => {
  const value = dataItem[field]
  const { symbol } = useSelector(selectProductById(dataItem.assetId)) || {}
  return (
    <Td>
      <div className="amount">
        <b>{value}</b> {symbol}
      </div>
    </Td>
  )
}

const Div = styled.div`
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .filter-wrapper {
      display: flex;

      .filter-token {
        margin-right: 23px;
      }

      .filter-time {
        margin-right: 15px;
      }

      .filter-time-from {
        margin-right: 17px;
      }

      .filter-time-to {
        margin-left: 17px;
      }

      .loading {
        margin-left: 20px;
      }
    }

    .MuiButton-root {
      text-transform: none;
      font-weight: normal;
      padding: 4px 15px;

      img {
        margin-right: 10px;
      }
    }
  }

  .table-list {
    min-width: 500px;

    colgroup {
      col:nth-child(1) {
        width: 190px;
      }
      col:nth-child(3) {
        width: 120px;
      }
      col:nth-child(4) {
        width: 140px;
      }
    }
  }

  @keyframes rowHighlight {
    from {
      background-color: #f8eacd;
    }
    to {
      background-color: none;
    }
  }

  .highlight {
    animation-name: rowHighlight;
    animation-duration: 4s;
  }

  @media (max-width: 1100px) {
    .table-list {
      colgroup {
        col:nth-child(1) {
          width: 105px;
        }
        col:nth-child(3) {
          width: 60px;
        }
        col:nth-child(4) {
          width: 105px;
        }
      }

      th {
        padding: 12px 10px;
      }

      td {
        padding: 9px 10px;
      }
    }
  }
`

const timeList = [
  { value: TIME_PERIOD.today, content: 'Today' },
  { value: TIME_PERIOD.yesterday, content: 'Yesterday' },
  { value: TIME_PERIOD.pastMonth, content: 'Past month' },
  { value: TIME_PERIOD.past3Month, content: 'Past 3 month' },
  { value: TIME_PERIOD.custom, content: 'Custom' },
]

export default () => {
  const dispatch = useDispatch()
  const branchId = useSelector(selectCurrentBranchId)
  const productList = useSelector(selectProduct)
  const { fetching, list, filter, total } = useSelector(selectTransaction)
  const qrcodeList = useSelector(selectQrcodeByBranchId(branchId)) || []
  const { assetId, period, fromDate, toDate, page, pageSize } = filter

  const assetList = qrcodeList.reduce((pre, cur) => [...pre, ...cur.assets.map((v) => v.id)], [])
  const filterProductList = Object.values(productList).filter((v) =>
    assetList.find((id) => id === v.id)
  )

  const tokenList = [
    { value: null, content: 'All token' },
    ...filterProductList.map((v) => ({ value: v.id, content: v.symbol })),
  ]

  const handleFilter = useCallback(
    (field) => (e) => {
      dispatch(
        updateTransactionFilter({
          page: 1,
          [field]: e.target ? e.target.value : e,
        })
      )
    },
    [dispatch]
  )

  const handleChangePage = useCallback(
    ({ skip }) => {
      dispatch(
        updateTransactionFilter({
          page: skip / pageSize + 1,
        })
      )
    },
    [dispatch, pageSize]
  )

  const handleExport = () => {
    dispatch(downloadTransactionList(branchId, filter))
  }

  const rowRender = (trElement, props) => {
    return React.cloneElement(
      trElement,
      {
        ...trElement.props,
        className: `${trElement.props.className}${props.dataItem.highlight ? ' highlight' : ''}`,
      },
      trElement.props.children
    )
  }

  useEffect(() => {
    dispatch(getTransactionList(branchId, filter))
    if (!assetList.length) {
      dispatch(getQrcode(branchId))
    }
  }, [branchId, dispatch, filter])

  return (
    <Div>
      <PageTitle>Transactions</PageTitle>
      <div className="table-header">
        <div className="filter-wrapper">
          <Select
            className="filter-token"
            value={assetId}
            onChange={handleFilter('assetId')}
            items={tokenList}
            displayEmpty
          />
          <Select
            className="filter-time"
            value={period}
            onChange={handleFilter('period')}
            items={timeList}
          />
          {period === TIME_PERIOD.custom && (
            <>
              <Date
                className="filter-time-from"
                emptyLabel="From"
                value={fromDate}
                onChange={handleFilter('fromDate')}
                maxDate={toDate || undefined}
                disableFuture
              />
              <span>-</span>
              <Date
                className="filter-time-to"
                emptyLabel="To"
                value={toDate}
                onChange={handleFilter('toDate')}
                minDate={fromDate || undefined}
                disableFuture
              />
            </>
          )}
          {fetching && <CircularProgress className="loading" size={32} />}
        </div>
        <Button className="button-export" variant="outlined" color="primary" onClick={handleExport}>
          <img src={iconDownload} />
          Export
        </Button>
      </div>
      <CustomTable
        className="table-list"
        data={list}
        page={page}
        pageSize={pageSize}
        total={total}
        onPageChange={handleChangePage}
        selectable={false}
        rowRender={rowRender}
      >
        <Column field="timestamp" title="Date Time" cell={CellDateTime} />
        <Column field="id" title="Transaction. ID" />
        <Column field="status" title="Status" cell={CellStatus} />
        <Column field="assetId" title="Token" cell={CellToken} />
        <Column field="from" title="From" />
        <Column field="amount" title="Amount" cell={CellAmount} />
      </CustomTable>
    </Div>
  )
}
