import moment from 'moment'
import { saveAs } from 'file-saver'

import { GET_TRANSACTION_LIST, UPDATE_TRANSACTION_FILTER } from 'redux/actionTypes'
import { selectCurrentBranchId, selectTransaction } from 'redux/selectors'
import { getAPI } from 'utils/api'
import { TIME_PERIOD } from 'constants/transaction.constant'
import { TRANSACTION_API_URL } from 'configs/app.config'

const getTransactionService = (
  { branchId, page, pageSize, assetId, period, fromDate, toDate } = {},
  isDownload = false
) => {
  const headers = {}
  let responseType = null
  let calFromDate = null
  let calToDate = null

  if (isDownload) {
    headers.accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    responseType = 'blob'
  }

  if (period === TIME_PERIOD.today) {
    calFromDate = moment().format('YYYY-MM-DD')
    calToDate = moment().add(1, 'day').format('YYYY-MM-DD')
  } else if (period === TIME_PERIOD.yesterday) {
    calFromDate = moment().subtract(1, 'day').format('YYYY-MM-DD')
    calToDate = moment().format('YYYY-MM-DD')
  } else if (period === TIME_PERIOD.pastMonth) {
    calFromDate = moment().subtract(1, 'month').format('YYYY-MM-DD')
    calToDate = moment().add(1, 'day').format('YYYY-MM-DD')
  } else if (period === TIME_PERIOD.past3Month) {
    calFromDate = moment().subtract(3, 'month').format('YYYY-MM-DD')
    calToDate = moment().add(1, 'day').format('YYYY-MM-DD')
  } else {
    calFromDate = fromDate ? moment(fromDate).format('YYYY-MM-DD') : null
    calToDate = toDate ? moment(toDate).add(1, 'day').format('YYYY-MM-DD') : null
  }

  return getAPI({
    url: `${TRANSACTION_API_URL}/v1/transactions`,
    headers,
    responseType,
    params: {
      branchId,
      page,
      pageSize,
      assetId,
      fromDate: calFromDate,
      toDate: calToDate,
      sort: 'timestamp',
      order: 'desc',
      type: 'Transfer',
      status: 'Success',
      timezone: 7,
    },
  })
}

export const getTransactionList =
  (branchId, filter = {}) =>
  (dispatch) => {
    const { period, fromDate, toDate } = filter
    if (period === TIME_PERIOD.custom && !fromDate && !toDate) {
      return null
    }

    dispatch({ type: GET_TRANSACTION_LIST.REQUEST })
    return getTransactionService({
      branchId,
      ...filter,
    })
      .then((data) => {
        const { items, total } = data
        dispatch({
          type: GET_TRANSACTION_LIST.SUCCESS,
          payload: {
            items,
            total,
          },
        })
      })
      .catch((error) => Promise.reject(dispatch({ type: GET_TRANSACTION_LIST.FAILURE, error })))
  }

export const updateTransactionList = () => (dispatch, getState) => {
  const branchId = selectCurrentBranchId(getState())
  const { filter, list } = selectTransaction(getState())

  return getTransactionService({
    branchId,
    ...filter,
  })
    .then((data) => {
      const { items, total } = data
      const newList = []
      let foundDuplicate = false

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < items.length; i++) {
        if (items[i].id !== list[0].id && !foundDuplicate) {
          newList.push({
            ...items[i],
            highlight: true,
          })
        } else {
          foundDuplicate = true
          newList.push(items[i])
        }
      }

      dispatch({
        type: GET_TRANSACTION_LIST.SUCCESS,
        payload: {
          items: newList,
          total,
        },
      })
    })
    .catch(() => {})
}

export const downloadTransactionList =
  (branchId, filter = {}) =>
  () => {
    const { period, fromDate, toDate } = filter
    const { page, pageSize, ...filterData } = filter
    if (period === TIME_PERIOD.custom && !fromDate && !toDate) {
      return null
    }

    return getTransactionService(
      {
        branchId,
        ...filterData,
      },
      true
    ).then((data) => {
      saveAs(data)
    })
  }

export const updateTransactionFilter =
  (filter = {}) =>
  (dispatch) => {
    dispatch({ type: UPDATE_TRANSACTION_FILTER, payload: { filter } })
  }
