import React from 'react'
import styled from 'styled-components'
import { Grid } from '@progress/kendo-react-grid'
import { IconButton, NativeSelect } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import iconArrowLeft from 'assets/images/arrow-left.svg'
import iconArrowRight from 'assets/images/arrow-right.svg'

const StyledGrid = styled(Grid)`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .k-grid-header {
    padding-right: 0 !important;
    background: #f8fafd;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: none;
    overflow: hidden;

    th {
      padding: 12px 20px;
      color: #2c2c2c;
      font-weight: normal;
      border: none;
    }
  }

  .k-grid-content {
    overflow: auto;

    td {
      word-break: break-all;
      padding: 9px 20px;
      border: none !important;
      border-bottom: 1px solid #eee !important;
    }
  }

  tr:hover {
    background: inherit !important;
  }
`

const StyledPager = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 20px;
  color: #5f666f;

  .paging {
    display: flex;
    align-items: center;

    .paging-content {
      margin: 0 10px;
    }

    .select-paging {
      margin-left: 10px;

      select {
        border: 1px solid #f6f7fa;
        background: #f8fafd;
        border-radius: 4px;
        font-size: 14px;
        color: #5f666f;
        padding: 6px 20px 6px 10px;
      }

      &:before,
      &:after {
        visibility: hidden;
      }
    }
  }

  .MuiIconButton-root.Mui-disabled {
    opacity: 0.3;
  }
`

const CustomPager = ({ total, skip, take, onPageChange }) => {
  const offset = skip + 1
  const totalPage = Math.ceil(total / take)
  const currentPage = Math.ceil(offset / take)

  const handlePageChange = (page) => {
    onPageChange({
      skip: (page - 1) * take,
      take,
    })
  }
  return (
    <StyledPager>
      <div className="info">
        {offset}-{Math.min(currentPage * take, total)} of {total} results
      </div>
      <div className="paging">
        <IconButton
          className="button-left"
          color="secondary"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <img src={iconArrowLeft} />
        </IconButton>
        <div className="paging-content">
          Page {currentPage} / {totalPage}
        </div>
        <IconButton
          className="button-right"
          color="secondary"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPage}
        >
          <img src={iconArrowRight} />
        </IconButton>
        <NativeSelect
          className="select-paging"
          value={currentPage}
          IconComponent={KeyboardArrowDownIcon}
          onChange={(e) => handlePageChange(e.target.value)}
        >
          {new Array(totalPage).fill().map((v, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <option key={i} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </NativeSelect>
      </div>
    </StyledPager>
  )
}

export default (props) => {
  const {
    data,
    total,
    page,
    pageSize,
    onPageChange = () => {},
    onRowClick = () => {},
    children,
    ...rest
  } = props

  return (
    <StyledGrid
      data={data}
      pageable={Boolean(data.length)}
      total={total}
      skip={(page - 1) * pageSize}
      take={pageSize}
      onPageChange={(e) => onPageChange(e.page)}
      onRowClick={({ dataItem }) => onRowClick(dataItem)}
      pager={CustomPager}
      {...rest}
    >
      {children}
    </StyledGrid>
  )
}
