import axios from 'axios'

import store from 'redux/store'
import { setAuth } from 'redux/actions/auth.action'
import { selectAccessToken } from 'redux/selectors'
import { BASE_API_URL } from 'configs/app.config'
import magicLink from 'utils/magicLink'

const BASE_API_VERSION = 'v1'
let errorCount = 0

const fetchApi = async (options = {}) => {
  const { url, headers, ...restOptions } = options
  const accessToken = selectAccessToken(store.getState())
  const defaultOptions = {
    baseURL: `${BASE_API_URL}/${BASE_API_VERSION}`,
    timeout: 30000,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {},
    params: {},
  }

  if (accessToken) {
    defaultOptions.headers.Authorization = `Bearer ${accessToken}`
  }

  defaultOptions.headers = {
    ...defaultOptions.headers,
    ...headers,
  }

  try {
    const { data } = await axios.request({
      url,
      ...defaultOptions,
      ...restOptions,
    })

    errorCount = 0
    return data
  } catch (error) {
    errorCount += 1
    if (error.response?.status === 401) {
      if (errorCount === 1) {
        // eslint-disable-next-line no-shadow
        const { headers, ...originalRequest } = error.config
        const token = await magicLink.user.getIdToken({ lifespan: 60 * 60 * 24 * 90 })
        store.dispatch(setAuth({ token }))
        return fetchApi(originalRequest)
      }
      window.location.href = '/login'
      return null
    }
    return Promise.reject(error)
  }
}

export const getAPI = (options = {}) => fetchApi(options)

export const postAPI = (options = {}) =>
  fetchApi({
    ...options,
    method: 'POST',
  })

export const putAPI = async (options = {}) =>
  fetchApi({
    ...options,
    method: 'PUT',
  })

export const patchAPI = async (options = {}) =>
  fetchApi({
    ...options,
    method: 'PATCH',
  })

export const delAPI = async (options = {}) =>
  fetchApi({
    ...options,
    method: 'DELETE',
  })
