import { GET_TRANSACTION_LIST, UPDATE_TRANSACTION_FILTER, SWITCH_BRANCH } from 'redux/actionTypes'
import { TIME_PERIOD } from 'constants/transaction.constant'

const mapTransaction = ({
  id,
  type,
  status,
  referenceId,
  assetId,
  senderEmailAddress,
  amount,
  timestamp,
  highlight,
}) => ({
  id,
  type,
  status,
  referenceId,
  assetId,
  from: senderEmailAddress,
  amount,
  timestamp,
  highlight,
})

const initialState = {
  fetching: false,
  list: [],
  filter: {
    assetId: null,
    period: TIME_PERIOD.today,
    fromDate: null,
    toDate: null,
    page: 1,
    pageSize: 50,
  },
  total: 0,
  error: null,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_TRANSACTION_LIST.REQUEST: {
      return {
        ...state,
        fetching: true,
      }
    }
    case GET_TRANSACTION_LIST.SUCCESS: {
      const {
        payload: { items = [], total },
      } = action
      const transactionList = items.map(mapTransaction)
      return {
        ...state,
        fetching: false,
        list: transactionList,
        total,
        error: null,
      }
    }
    case GET_TRANSACTION_LIST.FAILURE: {
      return {
        ...state,
        fetching: false,
        error: action.error,
      }
    }
    case UPDATE_TRANSACTION_FILTER: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload.filter,
        },
      }
    }
    case SWITCH_BRANCH: {
      return {
        ...state,
        filter: initialState.filter,
      }
    }
    default:
      return state
  }
}
