import { GET_PRODUCT_LIST } from 'redux/actionTypes'

const initialState = []

const mapProduct = ({ id, name, symbol, metadata: { imageUri } = {} }) => ({
  id,
  name,
  symbol,
  imageUri,
})

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_PRODUCT_LIST.SUCCESS:
      return action.payload.reduce((pre, cur) => ({ ...pre, [cur.id]: mapProduct(cur) }), {})
    default:
      return state
  }
}
