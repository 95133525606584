import moment from 'moment'
import _ from 'lodash'

const decimal = (number = 0, isFloat = true) => {
  const digit = isFloat ? 2 : 0
  return new Intl.NumberFormat('en-GB', {
    style: 'decimal',
    minimumFractionDigits: digit,
    maximumFractionDigits: digit,
  }).format(number)
}

const currency = (number = 0, symbol = '฿', isFloat) => `${symbol} ${decimal(number, isFloat)}`

export const TYPE = {
  DATE: 'Date',
  DATE2: 'Date2',
  TIME: 'Time',
  DATETIME: 'DateTime',
  NUMBER: 'Number',
  DECIMAL: 'Decimal',
  CURRENCY: 'Currency',
}

export default (type, value) => {
  const { DATE, DATE2, TIME, DATETIME, NUMBER, DECIMAL, CURRENCY } = TYPE
  switch (type) {
    case DATE:
      return _.isNil(value) ? '' : moment(new Date(value)).format('DD/MM/YYYY')
    case DATE2:
      return _.isNil(value) ? '' : moment(new Date(value)).format('DD MMM YYYY')
    case TIME:
      return _.isNil(value) ? '' : moment(new Date(value)).format('HH:mm:ss')
    case DATETIME:
      return _.isNil(value) ? '' : moment(new Date(value)).format('DD/MM/YYYY HH:mm')
    case NUMBER:
      return _.isNil(value) ? '' : decimal(value, false)
    case DECIMAL:
      return _.isNil(value) ? '' : decimal(value)
    case CURRENCY:
      return _.isNil(value) ? '' : currency(value)
    default:
      return value
  }
}
