const genRequest = (type) => ({
  REQUEST: `${type}_REQUEST`,
  SUCCESS: `${type}_SUCCESS`,
  FAILURE: `${type}_FAILURE`,
})

export const CLEAR_ALERT = 'CLEAR_ALERT'

export const SHOW_LOADING = 'SHOW_LOADING'
export const HIDE_LOADING = 'HIDE_LOADING'

export const GET_NOTIFICATION_LIST = genRequest('GET_NOTIFICATION_LIST')
export const UPDATE_NOTIFICATION_LIST = 'UPDATE_NOTIFICATION_LIST'
export const READ_NOTIFICATION = 'READ_NOTIFICATION'

export const SET_AUTH = 'SET_AUTH'
export const CLEAR_AUTH = 'CLEAR_AUTH'

export const GET_PROFILE = genRequest('GET_PROFILE')

export const GET_BRANCH = genRequest('GET_BRANCH')
export const SWITCH_BRANCH = 'SWITCH_BRANCH'

export const GET_PRODUCT_LIST = genRequest('GET_PRODUCT_LIST')

export const GET_QRCODE = genRequest('GET_QRCODE')

export const GET_TRANSACTION_LIST = genRequest('GET_TRANSACTION_LIST')
export const UPDATE_TRANSACTION_FILTER = 'UPDATE_TRANSACTION_FILTER'
