import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { withTheme } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Select from 'components/form/Select'
import Notification from 'containers/Notification'
import { selectProfile, selectBranch, selectCurrentBranch } from 'redux/selectors'
import { switchBranch } from 'redux/actions/branch.action'

import imgLogo from 'assets/images/logo.svg'

const Nav = withTheme(styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 56px;
  background: #fff;
  box-shadow: 0px 1px 4px rgba(23, 50, 84, 0.1);

  .menu-wrapper {
    display: none;

    .image-wrapper {
      background: #3886eb;
      width: 64px;
      height: 56px;

      img {
        display: block;
        width: 36px;
        margin: 20px auto;
      }
    }

    .hamberger-menu {
      width: 46px;
      text-align: center;
      color: #2c2c2c;
      border-right: 1px solid #f4f6f7;
      padding-top: 18px;

      svg {
        cursor: pointer;
      }
    }
  }

  .header-content {
    flex: 1;
    padding: 17px 24px;

    .left {
      width: auto;

      .company {
        font-weight: bold;
      }

      .branch {
        color: #5f666f;
        padding: 0;

        .MuiSelect-root {
          padding-left: 10px;
          padding-top: 4px;
          padding-bottom: 4px;
          border: none;
          background: #f8fafd;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      .user {
        margin-left: 10px;
        font-size: 12px;
        color: #5f666f;
      }

      .logout {
        margin-left: 10px;
        font-size: 12px;
        color: #3886eb;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @media (max-width: 850px) {
    .menu-wrapper {
      display: block;
    }
  }
`)

export default ({ onSidebarOpen }) => {
  const dispatch = useDispatch()
  const { emailAddress } = useSelector(selectProfile)
  const { selectedId, list: branchList } = useSelector(selectBranch)
  const { companyName } = useSelector(selectCurrentBranch) || {}

  const selectList = branchList.map((v) => ({ value: v.id, content: v.name }))

  return (
    <Nav>
      <div className="menu-wrapper">
        <Grid container>
          <Grid className="image-wrapper" item>
            <img src={imgLogo} />
          </Grid>
          <Grid className="hamberger-menu" onClick={onSidebarOpen} item>
            <FontAwesomeIcon icon={['fas', 'bars']} onClick={onSidebarOpen} />
          </Grid>
        </Grid>
      </div>

      <div className="header-content">
        <Grid container spacing={2} justify="space-between" alignItems="center">
          <Grid className="left" item container spacing={3} alignItems="center">
            <Grid className="company" item>
              {companyName}
            </Grid>
            <Grid className="branch" item>
              <Select
                items={selectList}
                value={selectedId}
                onChange={(e) => dispatch(switchBranch(e.target.value))}
              />
            </Grid>
          </Grid>
          <Grid className="right" item>
            <Notification />
            <div className="user">{emailAddress}</div>
            <Link className="logout" to="/logout">
              Log out
            </Link>
          </Grid>
        </Grid>
      </div>
    </Nav>
  )
}
