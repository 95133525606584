import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { selectProductById } from 'redux/selectors'

const Td = styled.td`
  > div {
    display: flex;
    align-items: center;
  }

  .icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
  }
`

export default ({ dataItem, field }) => {
  const value = dataItem[field]
  const { imageUri, symbol } = useSelector(selectProductById(value)) || {}

  return (
    <Td>
      <div>
        {imageUri ? <img className="icon" src={imageUri} /> : <div className="icon" />}
        <span>{symbol}</span>
      </div>
    </Td>
  )
}
