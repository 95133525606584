import { GET_QRCODE } from 'redux/actionTypes'
import { getAPI } from 'utils/api'

export const getQrcode = (branchId) => (dispatch) => {
  dispatch({ type: GET_QRCODE.REQUEST })
  return getAPI({
    url: `/branches/${branchId}/qrcodes`,
  })
    .then((data) => {
      dispatch({
        type: GET_QRCODE.SUCCESS,
        payload: {
          data,
          branchId,
        },
      })
    })
    .catch((error) => Promise.reject(dispatch({ type: GET_QRCODE.FAILURE, error })))
}
