import React from 'react'
import styled from 'styled-components'
import { Select as MaterialSelect, MenuItem } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

const StyledSelect = styled(MaterialSelect)`
  .MuiSelect-root {
    border: 1px solid #e1e4ea;
    background: #fff;
    border-radius: 8px;
    font-size: 14px;
    color: #2c2c2c;
    padding: 6px 30px 6px 16px;
  }

  .MuiSelect-icon {
    right: 5px;
    color: #2c2c2c;
  }

  &:before,
  &:after {
    visibility: hidden;
  }
`

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  color: #2c2c2c;

  &.Mui-selected,
  &.Mui-selected:hover {
    background: #3886eb;
    color: #fff;
  }
`

const Select = ({ items = [], ...rest }) => {
  return (
    <StyledSelect IconComponent={KeyboardArrowDownIcon} {...rest}>
      {items.map(({ value, content }) => (
        <StyledMenuItem key={value} value={value}>
          {content}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  )
}

export default Select
