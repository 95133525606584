import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

import Header from 'components/layout/Header'
import Sidebar from 'components/layout/Sidebar'
import Content from 'components/layout/Content'

const Div = styled.div`
  font-size: 16px;
  height: 100vh;

  .main-wrapper {
    height: 100%;
    overflow-y: hidden;
  }

  .main-content {
    .sidebar {
      width: 64px;
      min-width: 64px;
      height: 100vh;
      max-height: 100vh;
      overflow-y: auto;
    }

    .body {
      flex: 1;
      height: 100vh;
    }

    .content {
      flex: 1;
      overflow: auto;
    }
  }

  @media (max-width: 850px) {
    .main-content {
      .sidebar {
        position: absolute;
        width: 110px;
        z-index: 1;
        left: -110px;
        transition: 0.2s left;

        &.open {
          left: 0;
        }
      }
    }
  }

  @media print {
    .main-content {
      .sidebar,
      .header {
        display: none;
      }
    }
  }
`

export default ({ children }) => {
  const { pathname } = useLocation()
  const [sidebarOpen, setSidebarOpen] = useState(false)

  useEffect(() => {
    setSidebarOpen(false)
  }, [pathname])

  return (
    <Div>
      <div className="main-wrapper">
        <Grid className="main-content" container item wrap="nowrap">
          <Grid className={`sidebar ${sidebarOpen ? 'open' : ''}`} item>
            <Sidebar open={sidebarOpen} onSidebarClose={() => setSidebarOpen(false)} />
          </Grid>
          <Grid className="body" container item direction="column" wrap="nowrap">
            <div className="header">
              <Header onSidebarOpen={() => setSidebarOpen(true)} />
            </div>
            <Content className="content">{children}</Content>
          </Grid>
        </Grid>
      </div>
    </Div>
  )
}
