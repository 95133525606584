import React from 'react'
import styled from 'styled-components'

const Div = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  letter-spacing: 0.1px;
`

export default ({ children }) => <Div>{children}</Div>
