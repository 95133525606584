import React from 'react'
import styled from 'styled-components'

import formatValue, { TYPE } from 'utils/formatValue'

const Td = styled.td`
  word-break: break-word !important;

  span + span {
    font-size: 0.9em;
    color: #5f666f;
  }
`

export default ({ dataItem, field }) => {
  const value = dataItem[field]
  return (
    <Td>
      <span>{formatValue(TYPE.DATE2, value)}</span> <span>{formatValue(TYPE.TIME, value)}</span>
    </Td>
  )
}
