import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, useHistory } from 'react-router-dom'

import magicLink from 'utils/magicLink'
import { setAuth } from 'redux/actions/auth.action'
import { getProfile } from 'redux/actions/profile.action'
import { getBranch } from 'redux/actions/branch.action'
import { getProductList } from 'redux/actions/product.action'
import Preload from 'components/Preload'

const SecureRoute = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [checking, setChecking] = useState(true)

  useEffect(() => {
    const initLogin = async () => {
      try {
        const isLoggedIn = await magicLink.user.isLoggedIn()
        if (isLoggedIn) {
          const token = await magicLink.user.getIdToken({ lifespan: 60 * 60 * 24 * 90 })

          dispatch(setAuth({ token }))
          await dispatch(getProfile())
          await dispatch(getBranch())
          await dispatch(getProductList())
        } else {
          history.push('/login')
        }
      } finally {
        setChecking(false)
      }
    }
    initLogin()
  }, [dispatch, history])

  if (checking) {
    return <Preload />
  }

  return <Route {...props} />
}

export default SecureRoute
